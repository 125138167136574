import React from "react"

import styled from "@emotion/styled"

const MyBanner = styled.div`
  height: auto;
  width: 100%;
  padding: 3rem;
  background-color: white;
  margin-bottom: 3rem;
  .banner--inner {
    margin: auto;
    max-width: 70%;
  }
  && h1 {
    margin-bottom: 2rem;
  }
  && h6 {
    line-height: 2.5rem;
  }
  @media all and (max-width: 1000px) {
    .banner--inner {
      max-width: 85%;
    }
  }
  @media all and (max-width: 680px) {
    padding: 1rem;
    && h1 {
      font-size: 2rem;
      margin-top: 1rem;
    }
    && h6 {
      text-align: left;
      line-height: inherit;
    }
    .banner--inner {
      max-width: 100%;
    }
  }
  @media all and (max-width: 550px) {
    padding: 1.5rem;
    padding-top: 0;
    && h1 {
      font-size: 1.75rem;
      margin: 1rem;
    }
  }
`
const Banner = ({ title, content }) => {
  return (
    <MyBanner>
      <div className="banner--inner">
        <h1 className="blue">{title}</h1>
        <h6>{content}</h6>
      </div>
    </MyBanner>
  )
}

Banner.propTypes = {}

export default Banner
