import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import Image from "../Images"
import { navigate } from "gatsby"
const TheGrid = styled.div`
  display: grid;
  width: 90%;
  margin: auto;
  grid-template-columns: 50% 50%;
  /* grid-template-rows: repeat(2, auto); */
  @media all and (max-width: 1200px) {
    grid-template-columns: 50% 50%;
  }
  @media all and (max-width: 768px) {
    grid-template-columns: 100%;
    grid-template-rows: repeat(4, auto);
  }
`
const InnerImage = styled.div`
  min-height: auto;
  margin-bottom: 4rem;
  padding: 1rem;
  transition: all 0.2s ease;
  img {
    margin-bottom: 1rem;
  }
  h4 {
    font-weight: 600;
  }
  h4,
  p {
    max-width: 80%;
    margin: auto;
    margin-top: 0.5rem;
  }
  &&:hover {
    opacity: 0.8;
    cursor: pointer;
    filter: blur(6);
  }

  @media all and (max-width: 1200px) {
    min-height: auto;

    h4 {
      margin-top: 1rem;
    }
    img {
      margin-bottom: 0;
    }
    p {
      font-size: 1rem;
      line-height: 1.75rem;
    }
  }

  @media all and (max-width: 670px) {
    margin-bottom: 2rem;
    padding: 0;
    h4 {
      max-width: 90%;
      margin-top: 1rem;
      font-size: 1.5rem;
    }
    img {
      margin-bottom: 0;
    }
    p {
      max-width: 90%;
    }
  }
`
// const GridImage = styled(Image)`
//   width: 90%;
//   height: auto;
// `
const BigGrid = ({ data }) => {
  const [gridData, setGridData] = useState(null)

  useEffect(() => {
    setGridData(data)
  }, [data])

  return (
    <TheGrid>
      {gridData &&
        gridData.map(d => (
          <InnerImage key={d.id} onClick={() => navigate(d.link)}>
            <Image fileName={encodeURIComponent(d.title) + ".png"} />
            {/* <GridImage src={d.image}></GridImage> */}
            <h4 className="blue">{d.title}</h4>
            <p>{d.paragraph}</p>
          </InnerImage>
        ))}
    </TheGrid>
  )
}

export default BigGrid
