import React from "react"

import styled from "@emotion/styled"
import { navigate } from "gatsby"

const CTAHolder = styled.div`
  width: 100%;
  height: 10vh;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
const CTA = props => {
  return (
    <CTAHolder>
      <button className="btn" onClick={() => navigate("/contact-us")}>
        Contact Us
      </button>
    </CTAHolder>
  )
}

CTA.propTypes = {}

export default CTA
