import React, { useRef, useEffect } from "react"
import styled from "@emotion/styled"
import { Row, Container, Carousel } from "react-bootstrap"

import Image from "../Images"

const CaroImg = styled.img`
  width: inherit;
  height: auto;
`
const useScrollHandler = handler => {
  useEffect(() => {
    window.addEventListener("scroll", handler)
    return () => {
      window.removeEventListener("scroll", handler)
    }
  }, [])
}

const CarouselHead = ({ pics }) => {
  const caroRef = useRef()
  const handler = () => {
    caroRef.current.style.transform = `translate(0, ${window.scrollY / 3}px)`
  }

  useScrollHandler(handler)

  return (
    <Container fluid>
      <Row>
        <div
          ref={caroRef}
          style={{ width: "100%", height: "auto", zIndex: "0" }}
        >
          <Carousel pause={false}>
            {pics.map(pic => (
              <Carousel.Item key={pic.title}>
                {/* <CaroImg src={pic.src} /> */}
                <Image
                  fileName={pic.title + ".png"}
                  objectFit="contain"
                  loading="eager"
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </Row>
    </Container>
  )
}

export default CarouselHead
