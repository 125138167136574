import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { Row, Col, Container } from "react-bootstrap"

import { makeLink } from "../utils/google-pic-format"
import BigGrid from "../components/BigGrid"
import CTA from "../components/CTA"
import GreyCTA from "../components/CTA/grey-cta"
import CarouselHead from "../components/Carousel/CarouselHead"
import Banner from "../components/InfoBanner"
import LogoHolder from "../components/LogoHolder"
import FrostCenter from "../components/FrostCenter"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  const { header, services, partners, frost } = data
  const [headerPictures, setHeaderPictures] = useState([])
  const [gridData, setGridData] = useState([])

  useEffect(() => {
    const gridArray = []
    services.edges.forEach(edge => {
      if (edge.node.servicesSectionPicture) {
        let edgeObj = {}
        edgeObj["id"] = edge.node.servicesSectionTitle
        edgeObj["image"] = makeLink(edge.node.servicesSectionPicture)
        edgeObj["title"] = edge.node.servicesSectionTitle
        edgeObj["paragraph"] = edge.node.servicesSectionParagraph
        edgeObj["link"] = edge.node.linksTo

        gridArray.push(edgeObj)
      }
    })
    let headerPicArray = []
    header.edges.forEach(edge => {
      if (edge.node.headerPicture) {
        headerPicArray.push({
          src: makeLink(edge.node.headerPicture),
          title: edge.node.headerPictureTitle,
        })
      }
    })
    setGridData(gridArray)
    setHeaderPictures(headerPicArray)
  }, [header.edges, services.edges])

  return (
    <>
      <SEO title="Brazee | Home" />

      <Container
        fluid
        className="text-center white-bg px-0"
        style={{ width: "100vw" }}
      >
        <CarouselHead pics={headerPictures} />

        <Row noGutters>
          <Col md={12} className="white-bg">
            <Banner
              title={header.edges[0].node.paragraphHeading}
              content={header.edges[0].node.paragraphContent}
            />
          </Col>
        </Row>
        <Row noGutters>
          <Col md={12} className="white-bg">
            <BigGrid data={gridData} />
          </Col>
        </Row>
        <Row noGutters>
          <CTA></CTA>
          <GreyCTA>
            <h4>Partners We've Worked With</h4>
          </GreyCTA>
        </Row>
        <Row noGutters>
          <LogoHolder data={partners} />
        </Row>
        <Row noGutters>
          <FrostCenter data={frost} linkTo="/glass-retail-and-gift-shop" />
        </Row>
      </Container>
    </>
  )
}

export const query = graphql`
  query HomePageQuery {
    header: allGoogleSheetHomeRow {
      edges {
        node {
          headerPicture
          headerPictureTitle
          paragraphHeading
          paragraphContent
        }
      }
    }
    services: allGoogleSheetHomeRow {
      edges {
        node {
          servicesSectionTitle
          servicesSectionPicture
          servicesSectionParagraph
          linksTo
        }
      }
    }
    partners: allGoogleSheetHomeRow {
      edges {
        node {
          partners
          partnerPicture
        }
      }
    }
    frost: allGoogleSheetHomeRow {
      edges {
        node {
          frostedBgTitle
          frostedBgPicture
          frostedBgParagraph
        }
      }
    }
  }
`

export default IndexPage
