import React, { useEffect, useRef } from "react"

import styled from "@emotion/styled"
import { makeLink } from "../../utils/google-pic-format"
import { navigate } from "gatsby"

const FrostedBg = styled.div`
width: 100%;
/* background-size: cover; */
  background-image: url("${props => props.url}");
  background-repeat: no-repeat;
  /* background-position: center center; */
  /* background-attachment: fixed; */
  /* transition: all 100ms linear; */
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FrostWhite = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 750px;
  height: 500px;
  margin-top: 100px;
  margin-bottom: 100px;
  background-color: rgba(255, 255, 255, 0.8);
  transition: background-color 0.5s ease;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  h5,
  p {
    max-width: 70%;
    margin-bottom: 3rem;
  }
  &&:hover {
    background-color: rgba(255, 255, 255, 0.95);
    cursor: pointer;
  }
`
const FrostCenter = props => {
  const { data } = props
  const useScrollHandler = handler => {
    useEffect(() => {
      window.addEventListener("scroll", handler)
      return () => {
        window.removeEventListener("scroll", handler)
      }
    }, [])
  }
  const ref = useRef()
  const handler = () => {
    const height = window.innerHeight
    ref.current.style.backgroundPosition = `center ${window.scrollY / 10 -
      300}px`
  }
  useScrollHandler(handler)

  return (
    // <p>{JSON.stringify(data.edges)}</p>
    <FrostedBg ref={ref} url={makeLink(data?.edges[0]?.node?.frostedBgPicture)}>
      <FrostWhite>
        <h5>{data.edges[0].node.frostedBgTitle}</h5>
        <p>{data.edges[0].node.frostedBgParagraph}</p>
        <button onClick={() => navigate(props.linkTo)} className="btn">
          Learn More
        </button>
      </FrostWhite>
    </FrostedBg>
  )
}

FrostCenter.propTypes = {}

export default FrostCenter
